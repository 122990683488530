import { useContext, useState } from "react";
import {
  Box,
  TransparentButton,
  Text,
  SecondaryButton,
  TertiaryButton,
} from "flicket-ui";

import { Icon, ContentModal } from "~components";
import { BaseContext } from "~context";
import { formatDate } from "~lib";
import { ExtendedFile, LandingPage } from "~graphql/sdk";
import BaseHeader from "./common.BaseHeader";
import {
  getEventVenueLocation,
  VenueLike,
} from "~lib/helpers/getEventVenueLocation";
import { ZonedDate } from "@flicket/utils";
import { useOrganization } from "~hooks";

type BaseHeaderProps = {
  goBack: () => void;
  title: string;
  startDate: string;
  endDate?: string;
  type: "event" | "membership";
  image?: ExtendedFile;
  coordinates?: string;
  accessibility?: string;
  description?: string;
  supportInfo?: string;
};

type VenueOrLocation =
  | { venue: VenueLike; location?: never }
  | { venue?: never; location: string };

// Combine base props with venue or location
type EventHeaderProps = BaseHeaderProps & VenueOrLocation;

export default function EventHeader({
  title,
  startDate,
  endDate,
  location: maybeLocation,
  venue,
  coordinates,
  goBack,
  type,
  accessibility,
  description,
  image,
  supportInfo,
}: EventHeaderProps) {
  const { organization, hasFeature } = useOrganization();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isOpenSupport, setIsOpenSupport] = useState(false);

  const location = maybeLocation ?? getEventVenueLocation(venue);

  let backTxt = `Back to ${type === "membership" ? "memberships" : "schedule"}`;

  if (organization.landingPage === LandingPage.SingleEvent) {
    backTxt = `Back to tickets`;
  }

  const subTitle = (
    <>
      {ZonedDate.formatEventRange(startDate, endDate, {
        format: "basicLong",
        timeZone: organization.timezone,
        locale: organization.locale,
        showStartTime: !hasFeature("hideEventTime"),
      })}
      &nbsp;
      <Box as="span" d={{ _: "none", md: "block" }}>
        - {location}
      </Box>
    </>
  );

  return (
    <>
      <BaseHeader
        title={title}
        subTitle={subTitle}
        backTxt={backTxt}
        onClickBack={goBack}
        mobileMenuItems={
          <>
            {description && (
              <TransparentButton
                fontSize={3}
                fontWeight="regular"
                lineHeight="normal"
                color="N700"
                whiteSpace="nowrap"
                onClick={() => setIsOpenInfo(true)}
              >
                <Icon icon="info" fontSize={6} color="P300" mr="6/4" />
                Event info
              </TransparentButton>
            )}
            {accessibility && (
              <TransparentButton
                fontSize={3}
                fontWeight="regular"
                lineHeight="normal"
                color="N700"
                whiteSpace="nowrap"
                onClick={() => setIsOpen(true)}
              >
                <Icon icon="accessibility_purple" fontSize={6} mr="6/4" />
                Accessibility info
              </TransparentButton>
            )}
            {supportInfo && (
              <TransparentButton
                fontSize={3}
                fontWeight="regular"
                lineHeight="normal"
                color="N700"
                whiteSpace="nowrap"
                onClick={() => setIsOpenSupport(true)}
              >
                <Icon icon="support" fontSize={6} mr="6/4" />
                Support info
              </TransparentButton>
            )}
          </>
        }
        desktopMenuItems={
          <>
            {accessibility && (
              <TertiaryButton
                px="6/4"
                py={"9px" as any}
                onClick={() => setIsOpen(true)}
              >
                <Icon icon="accessibility" fontSize={5} mr={1} />
                <Text
                  color="N600"
                  fontSize={2}
                  fontWeight="extraBold"
                  lineHeight="normal"
                >
                  Accessibility
                </Text>
              </TertiaryButton>
            )}
            {description && (
              <SecondaryButton
                ml={2}
                px="6/4"
                py={"9px" as any}
                onClick={() => setIsOpenInfo(true)}
              >
                <Icon icon="info" fontSize={5} color="P300" mr={1} />
                <Text
                  color="P300"
                  fontSize={2}
                  fontWeight="extraBold"
                  lineHeight="normal"
                >
                  {organization.landingPage === LandingPage.SingleEvent
                    ? "Ticket"
                    : type === "event"
                    ? "Event"
                    : "Membership"}{" "}
                  Info
                </Text>
              </SecondaryButton>
            )}
            {supportInfo && (
              <SecondaryButton
                px="6/4"
                py={"9px" as any}
                ml={2}
                onClick={() => setIsOpenSupport(true)}
              >
                <Icon icon="support" fontSize={5} mr={1} />
                <Text
                  color="P300"
                  fontSize={2}
                  fontWeight="extraBold"
                  lineHeight="normal"
                >
                  Support
                </Text>
              </SecondaryButton>
            )}
          </>
        }
      >
        <>
          <ContentModal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            title="Accessibility"
            richText={accessibility}
          />
          {description && (
            <ContentModal
              isOpen={isOpenInfo}
              setIsOpen={() => setIsOpenInfo(false)}
              title={title}
              image={image}
              richText={description}
              subtitle={location}
              location={location}
              coordinates={coordinates}
              date={
                endDate
                  ? `${formatDate(startDate, "dd LLL yyyy")} - ${formatDate(
                      endDate,
                      "dd LLL yyyy"
                    )}`
                  : formatDate(startDate, "E, dd LLL yyyy p")
              }
            />
          )}
          {supportInfo && (
            <ContentModal
              isOpen={isOpenSupport}
              setIsOpen={() => setIsOpenSupport(false)}
              title="Support information"
              richText={supportInfo}
            />
          )}
        </>
      </BaseHeader>
    </>
  );
}
